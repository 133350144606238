import axios from "axios";
import React, { useState } from "react";

const WithdrawForm = () => {
  const [formData, setFormData] = useState({
    methods: "",
    amount: "",
    iban: "",
    acc_name: "",
    address: "",
    swift: "",
    refferance: "",
    acc_email: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Handle form submission logic
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .post("https://markdowntoday.ahmedul.com/api/withdraw", formData, config)
      .then((response) => {
        setSuccessMessage("Withdrawal request submitted successfully.");
        setErrorMessage("");
      })
      .catch((error) => {
        setSuccessMessage("");
        setErrorMessage(
          "Failed to submit withdrawal request. Please try again."
        );
        console.log(error);
      });
  };

  console.log("Method er jonno data:", formData);

  const renderWithdrawFields = () => {
    if (formData.methods === "paypal") {
      return (
        <>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="acc_email">
              Enter Account Email
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="acc_email"
              name="acc_email" // Update the name attribute to "acc_email"
              value={formData.acc_email}
              onChange={handleChange}
              placeholder="Enter account email"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="refferance"
            >
              Additional Reference
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="refferance"
              name="refferance"
              value={formData.refferance}
              onChange={handleChange}
              placeholder="Enter additional reference"
            />
          </div>
        </>
      );
    } else if (formData.methods === "bank") {
      return (
        <>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="iban">
              Enter iBAN / Account No
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="iban"
              name="iban"
              value={formData.iban}
              onChange={handleChange}
              placeholder="Enter iBAN or account number"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="acc_name">
              Enter Account Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="acc_name"
              name="acc_name" // Update the name attribute to "acc_name"
              value={formData.acc_name}
              onChange={handleChange}
              placeholder="Enter account name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="address">
              Enter Address
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter address"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="swift">
              Enter Swift Code
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="swift"
              name="swift"
              value={formData.swift}
              onChange={handleChange}
              placeholder="Enter Swift code"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="refferance"
            >
              Additional Reference
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="refferance"
              name="refferance"
              value={formData.refferance}
              onChange={handleChange}
              placeholder="Enter additional reference"
            />
          </div>
        </>
      );
    }
  };

  const renderWithdrawFeeText = () => {
    if (formData.methods === "paypal" || formData.methods === "bank") {
      return (
        <p className="text-sm text-gray-500">
          Withdraw Fee $5 and 5% will be deducted from your account.
        </p>
      );
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-full md:w-full lg:w-full xl:w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-4">Withdraw</h2>
        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
        <form
          onSubmit={handleSubmit}
          className="bg-base-200 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="methods">
              Withdraw Methods
            </label>
            <select
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="methods"
              name="methods" // Update the name attribute to "methods"
              value={formData.methods}
              onChange={handleChange}
            >
              <option value="">Select Methods</option>
              <option value="bank">Bank</option>
              <option value="paypal">PayPal</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="amount">
              Withdraw Amount
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter withdrawal amount"
            />
          </div>
          {renderWithdrawFields()}
          {renderWithdrawFeeText()}
          <div className="flex items-center justify-between">
            <button
              className="bg-[#ED3A58] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Withdraw
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WithdrawForm;
