import React, { useEffect, useState } from "react";
import CategorizedProductsCard from "./CategorizedProductsCard";
import NoProductsMessage from "./NoProductsMessage"; // Import the NoProductsMessage component
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../api/api";

const CategorizedProducts = () => {
  const { name } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/products/${name}`)
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, [name]);

  console.log("Ajker product data:", products);

  return (
    <div className="md:px-10 my-16">
      <h1 className="text-3xl  text-left bg-gradient-to-r from-red-400 to-orange-500 text-transparent bg-clip-text">
        Available products for <span className="font-bold">{name}</span>
        <hr className="my-3 " />
      </h1>
      <div className="flex items-center">
        {/* <p className="text-indigo-500 ml-3 cursor-pointer">See All</p> */}
      </div>
      {products.length === 0 ? ( // Check if products array is empty
        <NoProductsMessage /> // Render the NoProductsMessage component
      ) : (
        <div className="hidden md:grid md:grid-cols-4 md:gap-4 md:mt-1">
          {products.map((product) => (
            <CategorizedProductsCard key={product.id} product={product} />
          ))}
        </div>
      )}
      <div className="md:hidden">
        <div className="carousel carousel-center max-w-md space-x-4">
          <div className="carousel-item max-h-full max-w-2xl">
            {products.map((product) => (
              <CategorizedProductsCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorizedProducts;
