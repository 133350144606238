import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import WithdrawForm from "./WithdrawForm";
import WithdrawTable from "./WithdrawTable";

const Withdraw = () => {

  const [profile, setProfile]= useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get("https://markdowntoday.ahmedul.com/api/profile", config)
      .then((response) => {
        setProfile(response.data); // Assuming the response contains an array of withdraw data
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const availableAmount = profile?.affilate_income;
  const [showForm, setShowForm] = useState(false);

  const handleWithdraw = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="px-5">
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-6">
        <div className="bg-base-200 rounded-lg shadow p-4 flex justify-between">
          <div className="flex items-center justify-center md:justify-start">
            <FaDollarSign className="text-4xl text-[#ED3A58]" />
            <div className="ml-4 mr-5">
              <h2 className="text-xl font-bold">Available Balance</h2>
              <p className="">${profile?.affilate_income}</p>
            </div>
          </div>
          {showForm ? (
            <button
              className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
              onClick={handleCloseForm}
            >
              Withdrawal History
            </button>
          ) : (
            <button
              className="bg-[#ED3A58] hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleWithdraw}
            >
              New Withdraw
            </button>
          )}
        </div>
      </div>
      {showForm ? (
        <WithdrawForm handleCloseForm={handleCloseForm} />
      ) : (
        <WithdrawTable availableAmount={availableAmount} />
      )}
    </div>
  );
};

export default Withdraw;
