import React, { useEffect, useState } from "react";
import HotDealsCard from "./HotDealsCard";
import { BASE_URL } from "../../../api/api";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

const HotDeals = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/hot-deal`)
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);

  console.log("Ajker product data:", products);

  return (
    <div className="md:px-10 my-16">
      <h1 className="text-3xl font-bold text-left bg-gradient-to-r from-red-400 to-orange-500 text-transparent bg-clip-text">
        Hot Deals
        <hr className="my-3 " />
      </h1>
      <div className="flex items-center">
        {/* <p className="text-indigo-500 ml-3 cursor-pointer">See All</p> */}
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 md:mt-1">
        {products.map((product) => (
          <HotDealsCard key={product.id} product={product} />
        ))}
      </div>
      
    </div>
  );
};

export default HotDeals;
