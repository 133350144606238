import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductDetails from "../ProductDetails/ProductDetails";
import { BASE_URL, IMG_BASE_URL } from "./../../../api/api";
import SingleCashBackCard from "./SingleCashBackCard";

const CheckBackOffer = () => {
  const [data, setData] = useState([]);
  const [scrapedData, setScrapedData] = useState([]);
  const [product, setProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [brandsPerPage] = useState(10);

  const productHandler = (product) => {
    setProduct(product);
  };

  useEffect(() => {
    fetch(`${BASE_URL}/partners`)
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  useEffect(() => {
    fetch("https://markdowntoday.ahmedul.com/api/scraped-products")
      .then((response) => response.json())
      .then((data) => setScrapedData(data));
  }, []);

  // Get current brands
  const indexOfLastBrand = currentPage * brandsPerPage;
  const indexOfFirstBrand = indexOfLastBrand - brandsPerPage;
  const currentBrands = data.slice(indexOfFirstBrand, indexOfLastBrand);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="my-8">
      <div className="flex justify-center items-center my-16">
        <div>
          <h2 className="GradiantTextColour text-5xl my-1 text-center">
            Get Cash Back. Works online too!
          </h2>
          <p className="text-blace md:text-2xl text-center px-7 md:px-40 mt-5">
            Get up to 20% cashback at all your favourite websites plus $10
            cashback bonus on your first order! Download Markdowntoday app for
            easy on the go shopping. Just click Active Cash back whatever you
            shop for instant rewards.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-4 px-10 mt-6">
        {currentBrands.map((brand) => (
          <Link to={`/merchant/${brand.name}`} key={brand.name}> 
            <div className="rounded-xl bg-white cursor-pointer transform transition-transform duration-300 hover:-translate-y-1 hover:scale-110">
              <div className="lg:p-6 md:p-3 p-1 transform transition-transform duration-300 hover:-translate-y-1 hover:scale-110">
                <img
                  className="w-[70%] mx-auto"
                  src={`${IMG_BASE_URL}/partner/${brand.photo}`}
                  alt={brand.title}
                />
              </div>
              <h2 className="text-center text-lg pb-4 text-black">
                {brand.title}
              </h2>
            </div>
          </Link>
        ))}
      </div>

      <div className="flex justify-center mt-4">
        {data.length > brandsPerPage && (
          <ul className="pagination flex space-x-2">
            {Array.from(
              { length: Math.ceil(data.length / brandsPerPage) },
              (_, i) => (
                <li
                  key={i}
                  className={`pagination-item px-2 py-1 rounded ${
                    i + 1 === currentPage
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                  }`}
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </li>
              )
            )}
          </ul>
        )}
      </div>
      <h1 className="text-3xl font-bold text-left bg-gradient-to-r from-red-400 to-orange-500 text-transparent bg-clip-text px-10 mt-14">
        Recent Products
        <hr className="mt-3" />
      </h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-4 px-10 mt-6">
        {scrapedData.map((product, i) => (
          <SingleCashBackCard
            key={i}
            product={product}
            productHandler={productHandler}
          />
        ))}
      </div>

      <div className="hidden">
        <ProductDetails product={product} />
      </div>
    </div>
  );
};

export default CheckBackOffer;
