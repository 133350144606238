import { RouterProvider } from "react-router-dom";
import router from "./Routes/Routes/Routes";
import UserTracking from "./Pages/Shared/UserTracking/UserTracking";
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { LocationProvider } from "./LocationContext";
import { FacebookProvider } from "react-facebook";

// Custom hook to get location from local storage
const useLocation = () => {
  const [location, setLocation] = useState({ city: '', country: '' });

  useEffect(() => {
    const city = localStorage.getItem('city');
    const country = localStorage.getItem('country');
    setLocation({ city, country });
  }, []);

  return location;
};

function App() {
  document.title = "Markdowntoday | Amazing App for Shopping Deals and Coupon";

  const location = useLocation();

  useEffect(() => {
    const askForLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    };

    const showPosition = async (position) => {
      const { latitude, longitude } = position.coords;

      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCJylCkQYP1fS7O3cwThjQ4RvoxJ1XU_6o`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.results.length > 0) {
            const address = data.results[0].formatted_address;
            const city = getCityFromAddress(address);
            const country = getCountryFromAddress(address);

            // Store city and country name in local storage
            localStorage.setItem('city', city);
            localStorage.setItem('country', country);

            const locationString = `${country}, ${city}`;
            // showToast(locationString);

            console.log(`City: ${city}`);
            console.log(`Country: ${country}`);
          } else {
            console.log('Unable to retrieve address.');
          }
        } else {
          console.log('Error occurred while retrieving address.');
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    };

    const getCityFromAddress = (address) => {
      // Implement your logic to extract the city name from the address
      // Example: "New York, NY, USA" => "New York"
      return address.split(',')[0].trim();
    };

    const getCountryFromAddress = (address) => {
      // Implement your logic to extract the country name from the address
      // Example: "New York, NY, USA" => "USA"
      return address.split(',').slice(-1)[0].trim();
    };

    // const showToast = (locationString) => {
    //   const toastId = 'locationToast';
    //   const toastContent = (
    //     <div>
    //       <span>You are browsing from: </span>
    //       <strong>{locationString}</strong>
    //     </div>
    //   );
    
    //   if (!toast.isActive(toastId)) {
    //     toast.info(toastContent, {
    //       toastId: toastId,
    //       position: toast.POSITION.TOP_CENTER,
    //       autoClose: 5000, // Duration in milliseconds
    //     });
    //   }
    // };
    
    askForLocation();
  }, []);

  return (
    <div className="max-w-[1440px] mx-auto">
    <FacebookProvider  appId="103933209409766">
    <LocationProvider>
      <RouterProvider router={router}></RouterProvider>
      <UserTracking></UserTracking>

      {/* Display location in any component */}
      {/* <div>
        You are browsing from: {location.country}, {location.city}
      </div> */}

      {/* <ToastContainer /> */}
      </LocationProvider>
    </FacebookProvider>
    </div>
  );
}

export default App;
